import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/Header";
import SectionWrapper from "../components/sectionWrapper";
import OneColWrapper from "../components/oneColWrapper";
import Image from "../components/image";
import Text from "../components/text";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title="Privacy" mdxType="SEO" />
    <Layout mdxType="Layout">
  <Header bgcolor="#B187C4" mdxType="Header">
    <Text mdxType="Text">
      <h1>Privacy</h1>
      <p></p>
    </Text>
  </Header>
  <SectionWrapper mdxType="SectionWrapper">
    <OneColWrapper mdxType="OneColWrapper">
      <Text mdxType="Text">
        <h1>Let's get serious</h1>
        <p>
          This project exists because we want to protect your privacy. We want
          to keep the data we get from you as{" "}
          <a href="https://ar.al/2018/11/29/gdmr-this-one-simple-regulation-could-end-surveillance-capitalism-in-the-eu/">
            minimal
          </a>{" "}
          as possible. 
        </p>
        <h2>Adobe Fonts</h2>
        <p>
          So the only thing we need to mention here is the choice of our font provider. We have an expensive taste for fonts and as students we don't have the money to buy them and host them ourselves. That's why we use the Adobe Fonts service to use high quality fonts at a comparatively low price. In their{" "}
          <a href="https://blog.typekit.com/2013/06/13/clarifying-our-commitment-to-privacy/">
            Blog
          </a>{" "}
          they write: "Typekit makes a point not to track visitors on websites
          that use Typekit fonts. However, we do collect information about the
          fonts being served to each site. This data does not include any
          information about the users who are visiting a site serving Typekit
          fonts."
          <br />
          In fact, Adobe collects this data from us as a website provider, which is not cool and we are open to alternative:
          <ul>
                <li>Web Project ID</li>
                <li>Web Project JavaScript version (string)</li>
                <li>Web Project type (string "configurable" or "dynamic")</li>
                <li>Embed type (whether you are using the JavaScript or CSS embed code)</li>
                <li>Account ID (identifies the customer the Web Project is from)</li>
                <li>Service providing the fonts (e.g., Adobe Fonts or Edge Web Fonts)</li>
                <li>Application requesting the fonts (e.g., Adobe Muse)</li>
                <li>Server serving the fonts (e.g., Adobe Fonts servers or Enterprise CDN)</li>
                <li>Hostname of page loading the fonts</li>
                <li>The amount of time it takes the web browser to download the fonts</li>
                <li>The amount of time it takes from the web browser downloading the fonts until the fonts are applied</li>
                <li>Whether or not an ad blocker is installed to help identify whether the presence of an ad blocker affects accurate pageview tracking</li>
                <li>OS and browser version</li>
            </ul>
          <a href="https://www.adobe.com/privacy/policies/adobe-fonts.html">Find out more about the Adobe FontsPrivacy Policy</a>
        </p>
      </Text>
    </OneColWrapper>
  </SectionWrapper>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      